import React, { FunctionComponent, useCallback, useEffect } from "react"
import { Helmet as Head } from "react-helmet"
import Donations from "../components/comment/donations"
import ItemPreview from "../components/item/preview"
import MinimalSortFilter from "../components/layout/minimal-sort-filter"
import ResponsiveLayout from "../components/layout/responsive-layout"
import { useFilters } from "../hooks/use-filters"
import { useItem } from "../hooks/use-item"
import { useItems } from "../hooks/use-items"
import { useUser } from "../hooks/use-user"
import { convertContentItems } from "../services/content"
import { GET_item } from "../services/http"
import { LikeType, MuteType } from "../types"

const FeedbackPage: FunctionComponent = ({ location }) => {
  const parts = location.pathname.split("/")
  const itemId = parts[2]

  const { item, setItem } = useItem(undefined)
  const { updateItem, likeItem } = useItems([])
  const { user, updateCommentLikes, isLoggedIn } = useUser()
  const { filters } = useFilters()

  useEffect(() => {
    if (!item || item.id != itemId) {
      console.log("loading item " + itemId)
      GET_item(itemId).then(response => {
        if (response?.hits?.hits.length) {
          let items = convertContentItems(response, filters)
          if (items.length) {
            setItem(items[0])
            console.log("setting item url " + items[0].url)
          }
        }
      })
    }
  }, [filters, item, itemId])

  const onMute = useCallback((id: string, kind: MuteType) => {}, [])

  return (
    <>
      {item && (
        <Head>
          <title>{item.title} | Smartlike</title>
          <meta property="og:title" content={`${item.title} | Smartlike`} />
          <meta name="description" content={item.description} />
          <meta property="og:description" content={item.description} />
          {item.image && <meta property="og:image" content={item.image} />}
          <meta
            property="og:url"
            content={`${process.env.BASE_URL}/item/${item.id}`}
          />
        </Head>
      )}

      <ResponsiveLayout>
        {{
          content: (
            <>
              {item && (
                <>
                  <ItemPreview
                    liked={filters.liked}
                    item={item}
                    onLike={likeItem}
                    onUpdate={updateItem}
                    onMute={onMute}
                    onShowComments={undefined}
                  />

                  <Donations
                    target={{
                      kind: LikeType.Donate,
                      id: item?.id,
                      url: item?.url,
                      owned: item.owner == user?.id,
                      count: 0,
                    }}
                    parentComment={""}
                  />
                </>
              )}
            </>
          ),
          sidebar: <MinimalSortFilter />,
        }}
      </ResponsiveLayout>
    </>
  )
}
export default FeedbackPage
